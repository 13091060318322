/*
const config = {
  endPoint: 'http://localhost:8000',
  imagesPath: 'http://localhost:8000/images/'
};
*/

// on Deploy
const config = {
  endPoint: 'https://service.restadgard-utb.se/',
  imagesPath: 'https://service.restadgard-utb.se/images/'
};
export default config;
